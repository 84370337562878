import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { Badge, Bubble as BaseBubble } from 'components/ui';

const Header = styled.div`
  color: #2c2c2c;
  font-size: 14px;
  line-height: 20px;
`;

const Price = styled.div`
  color: #4A515C;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BadgeContainer = styled.div`
  display: flex;
  margin-top: 2px;
`;

const Wrapper = styled(BaseBubble)`
  display: flex;
  flex-direction: column;
  opacity: 1;

  ${Header} + ${Body} {
    margin-top: 10px;
  }

  ${Body} + ${BadgeContainer} {
    margin-left: 10px;
  }
`;

@withTheme
@inject('commissionVisibility')
@observer
class Bubble extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    commissionVisibility: PropTypes.object.isRequired,
    theme: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: '',
    withCommission: false
  };

  render() {
    const {
      hotel: { name, price_formation: { gross_price, net_price } },
      commissionVisibility: { isPercentVisible },
      t,
      ...rest
    } = this.props;

    const discount = (gross_price.amount - net_price.amount);

    return (
      <Wrapper {...rest}>
        <Header>
          {name}
        </Header>

        <Body>
          <Price>
            {t('Tariff.PriceFrom', { price: gross_price.amount })}
          </Price>

          {isPercentVisible && (
            <BadgeContainer>
              {discount > 0 && (
                <Badge variant='light'>
                  {t('UI.Discount', { value: discount })}
                </Badge>
              )}
            </BadgeContainer>
          )}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Bubble))``;
