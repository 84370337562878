import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  height: 100%;
  background: #f6f8fb;
  width: auto;

  ${({ hasOverflow }) => hasOverflow && css`
    overflow: auto;
  `};
`;

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 2;
  background: #9cb4ca;
  width: auto;
  min-width: fit-content;
`;

const Header = styled.div`
  width: 1394px;
  margin: 0 auto;
`;

const BodyWrapper = styled.div`
  ${display('flex')}
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 72px);
`;

const Body = styled.div`
  ${display('flex')}
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const FooterWrapper = styled.div``;

const Footer = styled.div``;

class FluidTemplate extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.node,
    body: PropTypes.node,
    footer: PropTypes.node,
    hasOverflow: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    header: null,
    body: null,
    footer: null,
    hasOverflow: true
  };

  render() {
    const { header, hasOverflow, body, footer, ...rest } = this.props;

    return (
      <Wrapper {...rest} hasOverflow={hasOverflow}>
        <HeaderWrapper>
          <Header>
            {header}
          </Header>
        </HeaderWrapper>
        <BodyWrapper>
          <Body>
            {body}
          </Body>
        </BodyWrapper>
        <FooterWrapper>
          <Footer>
            {footer}
          </Footer>
        </FooterWrapper>
      </Wrapper>
    );
  }
}

export default FluidTemplate;
