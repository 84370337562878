import { datesRange } from 'utils/dates';
import _flatten from 'lodash/flatten';
import _cloneDeep from 'lodash/cloneDeep';

import { groupsByReservation, groupsByTariff, groupsByDates, reservationsMapper } from './utils';

const VIEWS = {
  reservation: groupsByReservation,
  tariff: groupsByTariff,
  dates: groupsByDates
};

class OrderSerializer {
  constructor(order, options) {
    this.setOrder(order);
    this.setView(options);
  }

  // View
  view = undefined;

  setView({ view } = { view: undefined }) {
    const defaultView = 'tariff';

    this.view = Object.keys(VIEWS).includes(view)
      ? VIEWS[view]
      : VIEWS[defaultView];
  }

  // Order
  order = undefined;

  setOrder(order) {
    this.order = order;
  }

  get reservations() {
    const { order: { reservations } } = this;

    return reservations
      .filter(reservation => this.stateFilter(reservation))
      .map(reservation => {
        const slots = reservation.slots
          .filter(slot => this.stateFilter(slot));

        return { ...reservation, slots };
      });
  }

  stateFilter(item) {
    const { provider_state: { code: orderCode } } = this.order;
    const { state: itemCode } = item;

    return orderCode === 'cancelled' ? true : (itemCode !== 'cancelled');
  }

  get net_price() {
    const { net_price } = this.order;
    return net_price;
  }

  get gross_price() {
    const { gross_price } = this.order;
    return gross_price;
  }

  get discount() {
    const { reservations } = this;

    return reservations.reduce((sum, r) => (
      sum + r.commission.discount
    ), 0);
  }

  get discount_in_percent() {
    const { reservations } = this;
    const values = reservations.map(r => r.commission.discount_in_percent);

    let discount = Math.min(...values);
    discount = (discount * 10) / 10;

    return discount;
  }

  get extra_charge() {
    const { reservations } = this;

    return reservations.reduce((sum, r) => (
      sum + r.commission.extra_charge
    ), 0);
  }

  get extra_charge_in_percent() {
    const { reservations } = this;
    const values = reservations.map(r => r.commission.extra_charge_in_percent);

    let discount = Math.min(...values);
    discount = (discount * 10) / 10;

    return discount;
  }

  get rooms_count() {
    const { reservations } = this;
    return reservations.length;
  }

  get seats_count() {
    const { reservations } = this;

    let seats = reservations
      .map(item => item.slots);

    seats = _flatten(seats);

    return seats.length;
  }

  get dates_of_stay() {
    const { check_in, check_out } = this.order;
    return datesRange(check_in, check_out);
  }

  get contract() {
    const { contract } = this.order;
    return contract;
  }

  get groups() {
    const { view } = this;

    let reservations = _cloneDeep(this.reservations);
    reservations = reservationsMapper(reservations);

    return view(reservations);
  }

  execute() {
    const {
      order: { check_in, check_out, contract, payment_info },
      groups: { payment, groups },
      dates_of_stay,
      rooms_count,
      seats_count,
      net_price,
      gross_price,
      discount,
      discount_in_percent,
      extra_charge,
      extra_charge_in_percent
    } = this;

    return {
      check_in,
      check_out,
      payment_info,
      net_price,
      gross_price,
      discount,
      discount_in_percent,
      extra_charge,
      extra_charge_in_percent,
      dates_of_stay,
      contract,
      rooms_count,
      seats_count,
      payment,
      groups
    };
  }
}

export default OrderSerializer;
