import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, Field } from 'components/v2/forms';
import Button from 'components/forms/Button';
import { customizePhoneValue } from '../utils/customizePhoneNumber';
import { items } from './constants';

import Hint from './Hint';

const RegisterBtn = styled(Button)`
  height: 52px;
  width: 100%;
  margin-top: 36px;
`;

const InputComponent = styled(Input)`
  height: 40px;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
`;

const Content = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.div`
  padding: 40px;
  box-shadow: 0 2px 18px 9px rgba(74, 81, 92, 0.18);
  background: #fff;
`;

const Wrapper = styled.div`
  width: 400px;
`;

const ManagerForm = ({ className, registerLoading }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = useFormContext();

  const email = watch('email');
  const phone = watch('phone');

  const handleBlur = (name) => {
    switch (name) {
      case 'email': {
        setValue('legal_entity.email2', email);
        break;
      }

      case 'phone': {
        setValue('legal_entity.phone', phone);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <Wrapper>
      <Form className={className}>
        <Title>Данные менеджера</Title>
        <Content>
          {items.map(({ name, placeholder, label }) => (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { value, ...other } }) => (
                <Field required={!(name === 'middle_name' || name === 'post')} error={errors?.[name]} label={label}>
                  <InputComponent
                    {...other}
                    value={name === 'phone' ? customizePhoneValue(value) : value}
                    placeholder={placeholder}
                    onBlur={() => handleBlur(name)}
                  />
                </Field>
              )}
            />
          ))}
        </Content>
      </Form>

      <RegisterBtn disabled={registerLoading} loading={registerLoading} size='large'>
        Зарегистрироваться
      </RegisterBtn>

      <Hint />
    </Wrapper>
  );
};

ManagerForm.propTypes = {
  className: PropTypes.string,
  registerLoading: PropTypes.bool.isRequired
};

export default ManagerForm;
