import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/v2/ui/Tooltip';
import { AttentionIcon } from 'components/icons';
import { TooltipPrice } from 'components/ui/Tooltip';

const PriceContainer = styled.div`
  color: #4A515C;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

const PriceDescription = styled.div`
  color: #6B707B;
  font-family: "Helvetica Neue";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const PriceContent = styled.div`

`;

const DiscountValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DiscountContent = styled.div`
  padding: 2px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 3px;
  height: 34px;
`;

const DiscountPercent = styled.div`
  color: #E68686;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
`;

const DiscountValue = styled.div`
  color: #E68686;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
`;

const Wrapper = styled.div`
  width: 161px;
  height: 41px;
  padding: 4px 4px 8px 14px;
  box-sizing: border-box;
  border: 1px solid #3AA6D2;
  border-radius: 4px;
  background-color: #EFFAFF;
  display: flex;
  justify-content: space-between;

  ${AttentionIcon} {
    height: 10px;
    margin-left: 3px;
    width: 10px;

    path, rect {
      fill: #E68686 !important;
    }
    
  }
`;

const AgentPrice = ({ className, hotel }) => {
  const { t } = useTranslation();
  const {
    price_formation: {
      net_price,
      gross_price,
      discount_in_percent
    }
  } = hotel;
  const discount = (gross_price.amount - net_price.amount);

  const tooltipPrice = (
    <TooltipPrice
      t={t}
      net_price={net_price.amount}
      gross_price={gross_price.amount}
      discount_in_percent={discount_in_percent}
      discount={discount}
    />
  );

  return (
    <Tooltip
      placement='top'
      overlay={tooltipPrice}
    >
      <Wrapper className={className}>
        <PriceContent>
          <PriceContainer>
            {t('UI.Discount.From', { value: net_price.amount })}
          </PriceContainer>

          <PriceDescription>
            {t('UI.HotelCard.Price.Agent')}
          </PriceDescription>
        </PriceContent>

        <DiscountContent>
          <DiscountPercent>
            {discount_in_percent}%
        </DiscountPercent>

          <DiscountValueContainer>
            <DiscountValue>
              {t('UI.Price', { price: discount })}
            </DiscountValue>

            <AttentionIcon />
          </DiscountValueContainer>
        </DiscountContent>
      </Wrapper>
    </Tooltip>
  );
};

AgentPrice.propTypes = {
  className: PropTypes.string,
  hotel: PropTypes.object
};

export default styled(AgentPrice)``;
