import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { transition } from 'theme/mixins';
import getImage from 'utils/getImage';

import { Rating, Stars } from 'components/ui';
import Badge from 'components/ui/Badge';
import { TooltipPrice } from 'components/ui/Tooltip';
import InstantBooking from 'components/ui/InstantBooking';
import HotelPhoto from 'components/ui/HotelPhoto';
import { CornerDown as CornerDownIcon } from 'components/icons';
import { HotelInfoDialog } from '.';

const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #4A515C;
`;

const ExpandButton = styled.div`
  ${({ theme }) => css`
    position: relative;
    left: -8px;
    padding: 6px 8px;
    color: ${theme.colors.ui.primary};
    font-size: 13px;
    font-weight: 600;
    transition: color 0.25s;

    :hover {
      color: ${theme.colors.text.primary};
    }
  `}
`;

const PriceIcon = styled(CornerDownIcon)``;

const PriceDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: #3AA6D2;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 600;
  color: #3AA6D2;

  ${PriceIcon} {
    position: relative;
    top: 1px;
    width: 11px;
    height: 11px;
    margin-left: 8px;
    fill: currentColor;
    transform: rotate(270deg);
  }

  ${InstantBooking} {
    margin-left: 4px;
  }

  ${InstantBooking} + ${PriceIcon} {
    margin-left: 5px;
  }

  ${Badge} {
    position: relative;
    top: 2px;
  }
`;

const Info = styled.div``;

const BadgeContainer = styled.div`
  display: flex;
  margin-right: 8px;

  ${Badge} + ${Badge} {
    margin-left: 3px;
  }
`;

const InfoSection = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  ${Price} {
    margin-left: auto;
  }

  ${Price} + ${Badge} {
    margin-left: 10px;
  }
`;

const Address = styled.div`
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.colors.button.primaryHover};
    font-size: 13px;
    font-weight: 600;
  `}
`;

const Factor = styled.div`
  color: #979bab;
  font-size: 13px;
`;

const Factors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Factor}:not(:last-child)::after {
    content: "•";
    margin: 0 1em;
    font-size: 8px;
  }
`;

const Region = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${Address},
  ${Factor} {
    margin: 0 0 4px 0;
  }


  ${Address} + ${Factors} {
    margin-left: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${Rating} {
    margin-left: 8px;
  }
`;

const Photo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 14px;
`;

const Specialization = styled.li`
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpecializationItems = styled.ul``;

const Specializations = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
  `}
`;

const Details = styled.div`
  display: flex;
  padding: 6px 0;

  ${Specializations} {
    width: calc(100% - 145px);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  ${Details} {
    flex-grow: 1;
    margin-top: 2px;
  }
`;

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    background: ${theme.colors.light};
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 0 6px 2px rgba(36, 95, 119, 0.2);
    transition: box-shadow 0.25s, border-color 0.25s;
  `}
`;

const RatingAndStars = styled.div`
  display: flex;
  align-items: center;

  ${Rating} {
    margin-left: 6px;
  }
`;

const Wrapper = styled.div.attrs(({ link }) => ({ to: link }))`
  ${({ active, theme }) => css`
    position: relative;
    width: 100%;
    min-height: 144px;
    cursor: pointer;

    ::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: calc(100% - 28px);
      height: 100%;
      background: rgba(19, 136, 184, 0.4);
      filter: blur(8px);
      opacity: 0;
      transition: top 0.25s, opacity 0.25s;
    }

    ${PriceDetail} {
      margin-top: 4px;
    }

    :hover {
      ${Container} {
        border-color: ${theme.colors.active.primary};
        box-shadow: none;
      }

      ::after {
        top: 8px;
        opacity: 1;
      }
    }

    ${active && css`
      ${Container} {
        border-color: ${theme.colors.active.primary};
        box-shadow: none;
      }

      ::after {
        top: 8px;
        opacity: 1;
      }
    `}
  `}

  ${transition()};

  ${Content} {
    flex-grow: 1;
  }
`;

@inject('commissionVisibility')
@withTranslation()
@withTheme
@observer
class HotelCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object,
    commissionVisibility: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    onClick: PropTypes.func
  }

  static defaultProps = {
    className: '',
    active: false,
    onClick: () => { }
  }

  @observable isOpened = false;

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    onClick(e);
  }

  handleDetailsDialogOpen = (event) => {
    event.stopPropagation();
    this.isOpened = true;
  }

  handleDetailsDialogClose = () => {
    this.isOpened = false;
  }

  render() {
    const {
      active,
      hotel: {
        tariff,
        id,
        instant_booking,
        name,
        address: {
          region
        },
        rating,
        images,
        specializations,
        price_formation: {
          net_price,
          gross_price,
          discount_in_percent
        },
        treatment_factors,
        star
      },
      commissionVisibility: { isPercentVisible },
      t,
      className
    } = this.props;

    const thumbSize = { width: 233, height: 155 };
    const { src } = this.getImgPath(images[0], thumbSize);
    const discount = (gross_price.amount - net_price.amount);

    const tooltipPrice = (
      <TooltipPrice
        t={t}
        net_price={net_price.amount}
        gross_price={gross_price.amount}
        discount_in_percent={discount_in_percent}
        discount={discount}
      />
    );

    return (
      <>
        <Wrapper
          className={className}
          active={active}
          onClick={this.handleClick}
        >
          <Container>
            <Header>
              <Title>
                {name}
              </Title>

              {(Number(star) > 1 || rating > 0) && (
                <RatingAndStars>
                  {Number(star) > 1 && <Stars stars={Number(star)} />}

                  {rating > 0 && <Rating rating={rating} />}
                </RatingAndStars>
              )}
            </Header>

            <Content>
              <Region>
                {region && (
                  <Address>
                    {region}
                  </Address>
                )}

                <Factors>
                  {treatment_factors.map((factor, index) => (
                    <Factor key={index}>
                      {factor.name}
                    </Factor>
                  ))}
                </Factors>
              </Region>

              <Details>
                <Photo>
                  <HotelPhoto src={src} />
                </Photo>

                <Specializations>
                  <SpecializationItems>
                    {specializations.slice(0, 4).map(({ name }, index) => (
                      <Specialization key={index}>
                        {name}
                      </Specialization>
                    ))}
                  </SpecializationItems>
                </Specializations>
              </Details>
            </Content>

            <Footer>
              <ExpandButton onClick={this.handleDetailsDialogOpen}>
                {t('UI.Button.More')}
              </ExpandButton>

              <Info>
                <InfoSection>

                  <Price>
                    {isPercentVisible && (
                      <BadgeContainer>

                        {discount > 0 && (
                          <Badge
                            variant='light'
                            tooltipContent={tooltipPrice}
                          >
                            {t('UI.Discount', { value: discount })}
                          </Badge>
                        )}
                      </BadgeContainer>
                    )}

                    {t('UI.PriceFrom', { price: gross_price.amount })}

                    {instant_booking && <InstantBooking />}
                    <PriceIcon />
                  </Price>
                </InfoSection>

                {tariff && (
                  <InfoSection>
                    <PriceDetail>
                      {t('Orders.Reservations.ForDuration', {
                        value: tariff.duration.value,
                        unit: tariff.billing_hour
                      })}
                      {' / '}
                      {t('UI.Guests', { count: tariff.guests_count })}
                    </PriceDetail>
                  </InfoSection>
                )}
              </Info>
            </Footer>
          </Container>
        </Wrapper>

        <HotelInfoDialog
          id={id}
          onClick={this.handleClick}
          isOpened={this.isOpened}
          onClose={this.handleDetailsDialogClose}
        />
      </>
    );
  }
}

export default styled(HotelCard)``;
